import * as THREE from "three";
import { EventDispatcher } from '../../application/EventDispatcher';
import { WebGLRenderer } from './WebGLRenderer';

export const Events = {
  WEBGL_CONTEXT_LOST: 'webglcontextlost',
  WEBGL_CONTEXT_RESTORED: 'webglcontextrestored'
};
let BaseClass;
/// #if threejsVersion == 'R71'
{
  BaseClass = WebGLRenderer;
}
/// #else
{
  BaseClass = THREE.WebGLRenderer;
}
/// #endif


export class LMVRenderer extends BaseClass {
  #animationFrameCallbacks;
  #loadingAnimationDuration;

  constructor(params = {}) {
    super(params);
    
    params.canvas?.addEventListener('webglcontextlost', () => {
      this.fireEvent({ type: Events.WEBGL_CONTEXT_LOST });
    });
    params.canvas?.addEventListener('webglcontextrestored', () => {
      this.fireEvent({ type: Events.WEBGL_CONTEXT_RESTORED });
    });
    this.refCount = 0;
    
    this.#animationFrameCallbacks = [];
    this.#loadingAnimationDuration = -1;
    this.highResTimeStamp = -1;
  }

  /**
   * @public
   * @returns {boolean} True if multiple render targets is supprted in the current browser
   */
  supportsMRT() {
    return this.capabilities.isWebGL2 || (!this.extensions.get('WEBGL_draw_buffers'));
  }

  /**
   * Note: We might think of deleting this as this seems to be a workaround for browsers that only support WebGL and
   * are different than IE11
   * @public
   * We need to use more than WebGL 1.0 technically allows -- we use
   * different bit depth sizes for the render targets, which is not
   * legal WebGL 1.0, but will work eventually and some platforms/browsers
   * already allow it. For others, we have to try, check for failure, and disable use of MRT dynamically. 
   * @return {boolean}
   */
  verifyMRTWorks(renderTargets) {
    let isMRTWorking = false;
    if (this.supportsMRT()) {
      /// #if threejsVersion == 'R71'
      {
        isMRTWorking = this.initFrameBufferMRT(renderTargets, true);
      }
      /// #else
      {
        isMRTWorking = true;
      }
      /// #endif
    }
    return isMRTWorking;
  }


  updateTimestamp(highResTimeStamp) {
    return this.highResTimeStamp = highResTimeStamp;
  }

  getLoadingAnimationDuration() {
    return this.#loadingAnimationDuration;
  }

  setLoadingAnimationDuration(duration) {
    return this.#loadingAnimationDuration = duration;
  }  

  addAnimationLoop(callback) {
    this.#animationFrameCallbacks.push(callback);
    this.setAnimationLoop((time) => {
      for (let cb of this.#animationFrameCallbacks) {
        cb(time);
      }
    });
  }

  removeAnimationLoop(callback) {
    for (let i = 0; i < this.#animationFrameCallbacks.length; ++i) {
      if (this.#animationFrameCallbacks[i] === callback) {
        this.#animationFrameCallbacks.splice(i, 1);
        break;
      }
    }
    if (this.#animationFrameCallbacks.length === 0) {
      this.setAnimationLoop(null);
    }
  }

  clearBlend() {
    this.state.setBlending(THREE.NoBlending);
  }

  isWebGL2() {
    console.warn( 'LMVRenderer: .isWebGL2() has been deprecated. Use .capabilities.isWebGL2 instead.' );
    return this.capabilities.isWebGL2;
  }
}

EventDispatcher.prototype.apply(LMVRenderer.prototype);
LMVRenderer.Events = Events;